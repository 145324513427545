export const environment = {
  production: true,

  wordPressApi: 'https://wp-devleeds.streamamg.com/wp-json/wpa/v1',

  paymentScriptData: {
    name: 'streamPayments',
    src: 'https://leedspayments.streamamg.com/_resx/js/stream-payments.js',
    dependencies: [
      { name: 'jQuery', src: 'https://leedspayments.streamamg.com/Assets/Account/js/jquery.min.1.9.1.js' }
    ]
  },

  // base Url where the website is deployed, used for Payment integration template
  baseUrl: 'https://devleeds.streamamg.com'
};
