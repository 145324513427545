import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WordpressAdapterService, ClientResponse, Page, OtherContent } from 'wordpress-adapter';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {

  constructor(private wordpressAdapter: WordpressAdapterService) {
  }

  getOtherContent(): Observable<OtherContent> {
    return this.wordpressAdapter
      .request<OtherContent>(null, this.getOtherContentEndpoint())
      .pipe(
        map<ClientResponse<OtherContent>, OtherContent>((clientResponse) => {
          return this.handleResponse<OtherContent>(clientResponse);
        })
      );
  }

  getHomePage(): Observable<Page> {
    return this.getPage('home-page');
  }

  getBrowsePage(): Observable<Page> {
    return this.getPage('browse-page');
  }

  getBrowseAcademyPage(): Observable<Page> {
    return this.getPage('browse-academy-page');
  }

  getBrowseFeaturesPage(): Observable<Page> {
    return this.getPage('browse-features-page');
  }

  getBrowseHighlightsPage(): Observable<Page> {
    return this.getPage('browse-highlights-page');
  }

  getBrowseInterviewsPage(): Observable<Page> {
    return this.getPage('browse-interviews-page');
  }

  getBrowseVideosPage(): Observable<Page> {
    return this.getPage('browse-videos-page');
  }

  getSubscribePage(): Observable<Page> {
    return this.getPage('subscribe-page');
  }

  getFixturesPage(): Observable<Page> {
    return this.getPage('fixtures-page');
  }

  getFixturesFirstTeamPage(): Observable<Page> {
    return this.getPage('fixtures-first-team-page');
  }

  getLivePage(): Observable<Page> {
    return this.getPage('live-page');
  }

  getFaqPage(): Observable<Page> {
    return this.getPage('faq-page');
  }

  getPlaylistPage(): Observable<Page> {
    return this.getPage('playlist-page');
  }

  getBoxsetsPage(): Observable<Page> {
    return this.getPage('boxsets-page');
  }

  getPlayersPage(): Observable<Page> {
    return this.getPage('players-page');
  }

  getVideoPage(): Observable<Page> {
    return this.getPage('video-page');
  }

  getCookiePolicyPage(): Observable<Page> {
    return this.getPage('cookie-policy-page');
  }

  getPrivacyNoticePage(): Observable<Page> {
    return this.getPage('privacy-notice-page');
  }

  getTermsAndConditionsPage(): Observable<Page> {
    return this.getPage('terms-conditions-page');
  }

  private getPage(pageName: string, isJsonFile?: boolean): Observable<Page> {
    return this.wordpressAdapter
      .request<Page>(null, this.getPageEndpoint(pageName, isJsonFile))
      .pipe(
        map<ClientResponse<Page>, Page>((clientResponse) => {
          return this.handleResponse<Page>(clientResponse);
        })
      );
  }

  private handleResponse<T>(clientResponse: ClientResponse<T>): T {
    if (clientResponse.error) {
      return null;
    } else {
      return clientResponse.result;
    }
  }

  private getPageEndpoint(pageName: string, isJsonFile?: boolean): string {
    return isJsonFile
      ? `page/?slug=${pageName}&json=true`
      : `page/?slug=${pageName}`;
  }

  private getOtherContentEndpoint(): string {
    return 'shared_section';
  }
}
