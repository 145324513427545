import { NgModule } from '@angular/core';

import { WordpressAdapterModule } from 'wordpress-adapter';
import { WordpressComponentsModule } from 'wordpress-components';

import { environment } from '../../../environments/environment';

@NgModule({
  imports: [
    WordpressAdapterModule.forRoot({
      wordpressApiUrl: environment.wordPressApi
    }),
    WordpressComponentsModule.forRoot({
      streamPayments: environment.paymentScriptData,
      useCacheOnGridClick: true
    })
  ],
  exports: [
    WordpressAdapterModule,
    WordpressComponentsModule
  ]
})
export class SharedModule { }
