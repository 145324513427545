import { WordpressAdapterService } from 'wordpress-adapter';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "wordpress-adapter";
var WordpressService = /** @class */ (function () {
    function WordpressService(wordpressAdapter) {
        this.wordpressAdapter = wordpressAdapter;
    }
    WordpressService.prototype.getOtherContent = function () {
        var _this = this;
        return this.wordpressAdapter
            .request(null, this.getOtherContentEndpoint())
            .pipe(map(function (clientResponse) {
            return _this.handleResponse(clientResponse);
        }));
    };
    WordpressService.prototype.getHomePage = function () {
        return this.getPage('home-page');
    };
    WordpressService.prototype.getBrowsePage = function () {
        return this.getPage('browse-page');
    };
    WordpressService.prototype.getBrowseAcademyPage = function () {
        return this.getPage('browse-academy-page');
    };
    WordpressService.prototype.getBrowseFeaturesPage = function () {
        return this.getPage('browse-features-page');
    };
    WordpressService.prototype.getBrowseHighlightsPage = function () {
        return this.getPage('browse-highlights-page');
    };
    WordpressService.prototype.getBrowseInterviewsPage = function () {
        return this.getPage('browse-interviews-page');
    };
    WordpressService.prototype.getBrowseVideosPage = function () {
        return this.getPage('browse-videos-page');
    };
    WordpressService.prototype.getSubscribePage = function () {
        return this.getPage('subscribe-page');
    };
    WordpressService.prototype.getFixturesPage = function () {
        return this.getPage('fixtures-page');
    };
    WordpressService.prototype.getFixturesFirstTeamPage = function () {
        return this.getPage('fixtures-first-team-page');
    };
    WordpressService.prototype.getLivePage = function () {
        return this.getPage('live-page');
    };
    WordpressService.prototype.getFaqPage = function () {
        return this.getPage('faq-page');
    };
    WordpressService.prototype.getPlaylistPage = function () {
        return this.getPage('playlist-page');
    };
    WordpressService.prototype.getBoxsetsPage = function () {
        return this.getPage('boxsets-page');
    };
    WordpressService.prototype.getPlayersPage = function () {
        return this.getPage('players-page');
    };
    WordpressService.prototype.getVideoPage = function () {
        return this.getPage('video-page');
    };
    WordpressService.prototype.getCookiePolicyPage = function () {
        return this.getPage('cookie-policy-page');
    };
    WordpressService.prototype.getPrivacyNoticePage = function () {
        return this.getPage('privacy-notice-page');
    };
    WordpressService.prototype.getTermsAndConditionsPage = function () {
        return this.getPage('terms-conditions-page');
    };
    WordpressService.prototype.getPage = function (pageName, isJsonFile) {
        var _this = this;
        return this.wordpressAdapter
            .request(null, this.getPageEndpoint(pageName, isJsonFile))
            .pipe(map(function (clientResponse) {
            return _this.handleResponse(clientResponse);
        }));
    };
    WordpressService.prototype.handleResponse = function (clientResponse) {
        if (clientResponse.error) {
            return null;
        }
        else {
            return clientResponse.result;
        }
    };
    WordpressService.prototype.getPageEndpoint = function (pageName, isJsonFile) {
        return isJsonFile
            ? "page/?slug=" + pageName + "&json=true"
            : "page/?slug=" + pageName;
    };
    WordpressService.prototype.getOtherContentEndpoint = function () {
        return 'shared_section';
    };
    WordpressService.ngInjectableDef = i0.defineInjectable({ factory: function WordpressService_Factory() { return new WordpressService(i0.inject(i1.WordpressAdapterService)); }, token: WordpressService, providedIn: "root" });
    return WordpressService;
}());
export { WordpressService };
