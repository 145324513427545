import { Routes } from '@angular/router';
var routes = [
    {
        path: 'home',
        loadChildren: './pages/home/home.module#HomeModule'
    }, {
        path: 'browse',
        loadChildren: './pages/browse/browse.module#BrowseModule'
    }, {
        path: 'subscribe',
        loadChildren: './pages/subscribe/subscribe.module#SubscribeModule'
    }, {
        path: 'fixtures',
        loadChildren: './pages/fixtures/fixtures.module#FixturesModule'
    }, {
        path: 'live',
        loadChildren: './pages/live/live.module#LiveModule'
    }, {
        path: 'faq',
        loadChildren: './pages/faq/faq.module#FaqModule'
    }, {
        path: 'boxsets',
        loadChildren: './pages/boxsets/boxsets.module#BoxsetsModule'
    }, {
        path: 'players',
        loadChildren: './pages/players/players.module#PlayersModule'
    }, {
        path: 'playlist',
        loadChildren: './pages/playlist/playlist.module#PlaylistModule'
    }, {
        path: 'video',
        loadChildren: './pages/video/video.module#VideoModule'
    }, {
        path: 'cookie-policy',
        loadChildren: './pages/cookie-policy/cookie-policy.module#CookiePolicyModule'
    }, {
        path: 'privacy-notice',
        loadChildren: './pages/privacy-notice/privacy-notice.module#PrivacyNoticeModule'
    }, {
        path: 'terms-and-conditions',
        loadChildren: './pages/terms-and-conditions/terms-and-conditions.module#TermsAndConditionsModule'
    },
    { path: '**', redirectTo: 'home', pathMatch: 'full' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
