import { OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { WordpressService } from './core/service/wordpress/wordpress.service';
import { WindowRef } from '../WindowRef';
var AppComponent = /** @class */ (function () {
    function AppComponent(titleService, router, wordpressService, winRef) {
        this.titleService = titleService;
        this.router = router;
        this.wordpressService = wordpressService;
        this.winRef = winRef;
        this.titleService.setTitle('Leeds United FC');
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                winRef.nativeWindow.ga('set', 'page', event.urlAfterRedirects);
                winRef.nativeWindow.ga('send', 'pageview');
            }
        });
    }
    AppComponent.prototype.ngOnDestroy = function () {
        this.otherContentSubscription.unsubscribe();
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.otherContentSubscription = this.wordpressService.getOtherContent().subscribe(function (response) {
            _this.otherContent = response;
        });
    };
    AppComponent.prototype.isHomePage = function () {
        return this.getPath() === 'home';
    };
    AppComponent.prototype.getPath = function () {
        var page = '';
        if (this.router.url !== '/') {
            var urlTree = this.router.parseUrl(this.router.url);
            page = urlTree.root.children.primary.segments.map(function (it) { return it.path; }).join('/');
        }
        return page;
    };
    return AppComponent;
}());
export { AppComponent };
