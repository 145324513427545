import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {
  NavigationEnd,
  Router
} from '@angular/router';
import {Subscription} from 'rxjs';
import {OtherContent} from 'wordpress-adapter';
import {WordpressService} from './core/service/wordpress/wordpress.service';
import {WindowRef} from '../WindowRef';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public otherContent: OtherContent;

  private otherContentSubscription: Subscription;

  constructor(private titleService: Title,
              private router: Router,
              private wordpressService: WordpressService,
              private winRef: WindowRef) {
    this.titleService.setTitle('Leeds United FC');

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        winRef.nativeWindow.ga('set', 'page', event.urlAfterRedirects);
        winRef.nativeWindow.ga('send', 'pageview');
      }
    });
  }

  ngOnDestroy(): void {
    this.otherContentSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.otherContentSubscription = this.wordpressService.getOtherContent().subscribe(response => {
      this.otherContent = response;
    });
  }

  isHomePage(): boolean {
    return this.getPath() === 'home';
  }

  getPath(): string {
    let page = '';
    if (this.router.url !== '/') {
      const urlTree = this.router.parseUrl(this.router.url);
      page = urlTree.root.children.primary.segments.map(it => it.path).join('/');
    }
    return page;
  }

}
