/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./core/component/header/header.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "./core/component/header/header.component";
import * as i5 from "@angular/router";
import * as i6 from "./core/component/footer/footer.component.ngfactory";
import * as i7 from "./core/component/footer/footer.component";
import * as i8 from "./core/component/overlay/overlay.component.ngfactory";
import * as i9 from "./core/component/overlay/overlay.component";
import * as i10 from "./app.component";
import * as i11 from "@angular/platform-browser";
import * as i12 from "./core/service/wordpress/wordpress.service";
import * as i13 from "../WindowRef";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "mobile-only": 0 }), i1.ɵdid(4, 49152, null, 0, i4.HeaderComponent, [i5.Router], { header: [0, "header"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.isHomePage()); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.otherContent.header; _ck(_v, 4, 0, currVal_1); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["id", "page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-footer", [], null, null, null, i6.View_FooterComponent_0, i6.RenderType_FooterComponent)), i1.ɵdid(2, 49152, null, 0, i7.FooterComponent, [], { footer: [0, "footer"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.otherContent.footer; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-overlay", [], null, null, null, i8.View_OverlayComponent_0, i8.RenderType_OverlayComponent)), i1.ɵdid(2, 49152, null, 0, i9.OverlayComponent, [], { overlay: [0, "overlay"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.otherContent.overlay; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["id", "root-container"]], [[1, "data-page", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.otherContent && _co.otherContent.header); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.otherContent; _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.otherContent && _co.otherContent.footer); _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.otherContent && _co.otherContent.overlay); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPath(); _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i10.AppComponent, [i11.Title, i5.Router, i12.WordpressService, i13.WindowRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
