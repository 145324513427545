import { Component, Input } from '@angular/core';
import { Overlay } from 'wordpress-adapter';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent {
  @Input() overlay: Overlay;
}
