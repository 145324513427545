<div id="root-container" [attr.data-page]="getPath()">
  <ng-container *ngIf="otherContent && otherContent.header">
    <app-header [header]="otherContent.header" [ngClass]=" { 'mobile-only': isHomePage() }"></app-header>
  </ng-container>

  <ng-container *ngIf="otherContent">
    <div id="page-container">
      <router-outlet [class.page-container]></router-outlet>
    </div>
  </ng-container>

  <ng-container *ngIf="otherContent && otherContent.footer">
    <app-footer [footer]="otherContent.footer"></app-footer>
  </ng-container>

  <ng-container *ngIf="otherContent && otherContent.overlay">
    <app-overlay [overlay]="otherContent.overlay"></app-overlay>
  </ng-container>
</div>
