import { Component, Input } from '@angular/core';
import { Footer } from 'wordpress-adapter';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() footer: Footer;

  constructor() {
  }
}
